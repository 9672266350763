import AppSupportComponent from "../components/AppSupport/AppSupportComponent";
import Breadcrumbs from "../components/breadcrumbs/Breadcrumbs";

const AppSupport = () => {
  return (
    <>
      <Breadcrumbs>Custom Applications</Breadcrumbs>
      <AppSupportComponent />
    </>
  );
};

export default AppSupport;
