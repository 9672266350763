import Breadcrumbs from "../components/breadcrumbs/Breadcrumbs";
import TestimonialItems from "../components/testimonialitems/TestimonialItems";

export default function Testimonials() {
  return (
    <>
      <Breadcrumbs>Testimonials</Breadcrumbs>
      <TestimonialItems />
    </>
  );
}
