import CloudMigrationComponent from "../components/MultiCloudDeployment/CloudMigration/CloudMigrationComponent";
import Breadcrumbs from "../components/breadcrumbs/Breadcrumbs";

const CloudMigration = () => {
  return (
    <>
      <Breadcrumbs>Cloud Migration</Breadcrumbs>
      <CloudMigrationComponent />
    </>
  );
};

export default CloudMigration;
