import { useEffect, useRef, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import checkPageStatus from "../../services/checkPageStatus";
import classes from "./Header.module.css";

interface IContact {
  siteName: string;
  addressLine1: string;
  addressLine2: string;
  email: string;
  phone: string;
}

export default function Header() {
  const btnRef = useRef<HTMLElement>(null);
  const navRef = useRef<HTMLElement>(null);
  const dropdownRef = useRef<HTMLUListElement>(null);
  const [navBar, setnavBarStatus] = useState(false);
  const [dropdown, setdropdownStatus] = useState(false);
  const location = useLocation();

  const contact = useFetch<IContact>("./data/contact.json", {
    siteName: "",
    addressLine1: "",
    addressLine2: "",
    phone: "",
    email: "",
  });

  useEffect(() => {
    if (window.innerWidth > 990) return;
    console.log(location.pathname);
    if (navBar) {
      navRef.current?.classList.remove(classes.navbar_mobile);
      btnRef.current?.classList.remove(classes.bi_x, "bi-x");
      btnRef.current?.classList.add("bi-list");
      setnavBarStatus(false);
    }
    if (dropdown) {
      dropdownRef.current?.classList.remove(classes.dropdown_active);
      setdropdownStatus(false);
    }
  }, [location.pathname]);

  const toggleMobileNav = (event: React.MouseEvent<HTMLElement>) => {
    if (window.innerWidth > 990) return true;
    if (navBar) {
      navRef.current?.classList.remove(classes.navbar_mobile);
      event.currentTarget.classList.remove(classes.bi_x, "bi-x");
      event.currentTarget.classList.add("bi-list");
      setnavBarStatus(false);
    } else {
      navRef.current?.classList.add(classes.navbar_mobile);
      event.currentTarget.classList.remove("bi-list");
      event.currentTarget.classList.add(classes.bi_x, "bi-x");
      setnavBarStatus(true);
    }
  };

  const toggleDropDown = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    if (window.innerWidth > 990) return true;
    if (dropdown) {
      dropdownRef.current?.classList.remove(classes.dropdown_active);
      setdropdownStatus(false);
    } else {
      dropdownRef.current?.classList.add(classes.dropdown_active);
      setdropdownStatus(true);
    }
  };

  return (
    <header
      className={`${classes.page_header} fixed-top d-flex align-items-center`}
    >
      <div className="container d-flex align-items-center">
        <h1 className={`${classes.logo} me-auto`}>
          <Link to="/" className={`${classes.header_link}`}>
            <img src="/dsk-labs.png" alt={contact.siteName} />
          </Link>
        </h1>
        <nav ref={navRef} className={`${classes.navbar} navbar`}>
          <ul className={`${classes.navbar_ul}`}>
            <li className={`${classes.navbar_li}`}>
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive
                    ? `${classes.navbar_link} ${classes.active}`
                    : `${classes.navbar_link}`
                }
              >
                Home
              </NavLink>
            </li>
            <li className={`${classes.navbar_li} ${classes.dropdown} dropdown`}>
              <a
                href="/"
                className={`${classes.navbar_link}`}
                onClick={toggleDropDown}
              >
                <span>About</span> <i className="bi bi-chevron-down"></i>
              </a>
              <ul
                ref={dropdownRef}
                className={`${classes.navbar_ul} ${classes.dropdown_ul}`}
              >
                {checkPageStatus("About") ? (
                  <li className={`${classes.navbar_li} ${classes.dropdown_li}`}>
                    <NavLink
                      to="/about"
                      className={({ isActive }) =>
                        isActive
                          ? `${classes.navbar_link} ${classes.dropdown_link} ${classes.active}`
                          : `${classes.navbar_link} ${classes.dropdown_link}`
                      }
                    >
                      About
                    </NavLink>
                  </li>
                ) : null}

                {checkPageStatus("Team") ? (
                  <li className={`${classes.navbar_li} ${classes.dropdown_li}`}>
                    <NavLink
                      to="/team"
                      className={({ isActive }) =>
                        isActive
                          ? `${classes.navbar_link} ${classes.dropdown_link} ${classes.active}`
                          : `${classes.navbar_link} ${classes.dropdown_link}`
                      }
                    >
                      Team
                    </NavLink>
                  </li>
                ) : null}

                {checkPageStatus("Testimonials") ? (
                  <li className={`${classes.navbar_li} ${classes.dropdown_li}`}>
                    <NavLink
                      to="/testimonials"
                      className={({ isActive }) =>
                        isActive
                          ? `${classes.navbar_link} ${classes.dropdown_link} ${classes.active}`
                          : `${classes.navbar_link} ${classes.dropdown_link}`
                      }
                    >
                      Testimonials
                    </NavLink>
                  </li>
                ) : null}
              </ul>
            </li>
            {checkPageStatus("Services") ? (
              <li className={`${classes.navbar_li}`}>
                <NavLink
                  to="/services"
                  className={({ isActive }) =>
                    isActive
                      ? `${classes.navbar_link} ${classes.active}`
                      : `${classes.navbar_link}`
                  }
                >
                  Services
                </NavLink>
              </li>
            ) : null}

            {checkPageStatus("Portfolio") ? (
              <li className={`${classes.navbar_li}`}>
                <NavLink
                  to="/portfolio"
                  className={({ isActive }) =>
                    isActive
                      ? `${classes.navbar_link} ${classes.active}`
                      : `${classes.navbar_link}`
                  }
                >
                  Portfolio
                </NavLink>
              </li>
            ) : null}

            {checkPageStatus("Pricing") ? (
              <li className={`${classes.navbar_li}`}>
                <NavLink
                  to="/pricing"
                  className={({ isActive }) =>
                    isActive
                      ? `${classes.navbar_link} ${classes.active}`
                      : `${classes.navbar_link}`
                  }
                >
                  Pricing
                </NavLink>
              </li>
            ) : null}

            {checkPageStatus("Blog") ? (
              <li className={`${classes.navbar_li}`}>
                <NavLink
                  to="/blog"
                  className={({ isActive }) =>
                    isActive
                      ? `${classes.navbar_link} ${classes.active}`
                      : `${classes.navbar_link}`
                  }
                >
                  Blog
                </NavLink>
              </li>
            ) : null}
            {checkPageStatus("Contact") ? (
              <li className={`${classes.navbar_li}`}>
                <NavLink
                  to="/contact"
                  className={({ isActive }) =>
                    isActive
                      ? `${classes.navbar_link} ${classes.active}`
                      : `${classes.navbar_link}`
                  }
                >
                  Contact
                </NavLink>
              </li>
            ) : null}
          </ul>
          <i
            ref={btnRef}
            className={`bi bi-list ${classes.mobile_nav_toggle}`}
            onClick={toggleMobileNav}
          ></i>
        </nav>
      </div>
    </header>
  );
}
