import Breadcrumbs from "../components/breadcrumbs/Breadcrumbs";
import PortfolioItems from "../components/porfolioitems/PortfolioItems";

export default function Portfolio() {
  return (
    <>
      <Breadcrumbs>Portfolio</Breadcrumbs>
      <PortfolioItems />
    </>
  );
}
