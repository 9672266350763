import React from "react";
import PageNotFound from "../components/pageNotFound/PageNotFound";

export default function NotFoundPage() {
  return (
    <>
      <PageNotFound />
    </>
  );
}
