import Breadcrumbs from "../components/breadcrumbs/Breadcrumbs";
import DotNetSolutionsComponent from "../components/dotNetSolutions/DotNetSolutionsComponent";

const DotNetSolutions = () => {
  return (
    <>
      <Breadcrumbs>Azure Data Solution</Breadcrumbs>
      <DotNetSolutionsComponent />
    </>
  );
};

export default DotNetSolutions;
