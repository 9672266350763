import OcrComponent from "../components/Ocr/OcrComponent";
import Breadcrumbs from "../components/breadcrumbs/Breadcrumbs";

const OcrSolutions = () => {
  return (
    <>
      <Breadcrumbs>OCR Solutions</Breadcrumbs>
      <OcrComponent />
    </>
  );
};

export default OcrSolutions;
