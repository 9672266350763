import Breadcrumbs from "../components/breadcrumbs/Breadcrumbs";
import DatabaseSolutionsComponent from "../components/databaseSolutions/DatabaseSolutions";

const DatabaseSolutions = () => {
  return (
    <>
      <Breadcrumbs>Database Solution</Breadcrumbs>
      <DatabaseSolutionsComponent />
    </>
  );
};

export default DatabaseSolutions;
