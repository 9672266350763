import emailjs from "@emailjs/browser";
import { useRef } from "react";
import toast from "react-hot-toast";
import useFetch from "../../hooks/useFetch";
import classes from "./ContactInfo.module.css";

interface IContact {
  addressLine1: string;
  addressLine2: string;
  phone: string;
  email: string;
}

export default function ContactInfo() {
  const contact = useFetch<IContact>("./data/contact.json", {
    addressLine1: "",
    addressLine2: "",
    phone: "",
    email: "",
  });

  const form = useRef();

  const sendEmail = (e: HTMLFormElement) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_v9wp0b8", //serviceId: Khurram Khan

        "template_q7wy2ei", //templateId: Khurram Khan

        // @ts-ignore
        form.current,
        "shsgAW0OnV2pi0Ktl" //public Key: Khurram Khan
      )
      .then(
        (result) => {
          console.log(result.text);
          toast.success("Your message has been sent. Thank you!");
        },
        (error) => {
          console.error(error.text);
          toast.error("Error sending your message. Please try again!");
        }
      );
  };

  return (
    <section className="contact">
      <div className="container">
        <div>
        <iframe width="100%" height="270px" id="gmap_canvas" src="https://maps.google.com/maps?width=1116&amp;height=270&amp;hl=en&amp;q=%20irvine+()&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe> <script type='text/javascript' src='https://embedmaps.com/google-maps-authorization/script.js?id=45f0e874c2a19c4559c691aee5e87d8473971839'></script>
        </div>

        <div className="row mt-5">
          <div className="col-lg-4">
            <div className={classes.info}>
              <div className={classes.address}>
                <i className="bi bi-geo-alt"></i>
                <h4>Location:</h4>
                <p>{contact.addressLine1 + ", " + contact.addressLine2}</p>
              </div>

              <div className={classes.email}>
                <i className="bi bi-envelope"></i>
                <h4>Email:</h4>
                <p>{contact.email}</p>
              </div>

              <div className={classes.phone}>
                <i className="bi bi-phone"></i>
                <h4>Call:</h4>
                <p>{contact.phone}</p>
              </div>
            </div>
          </div>

          <div className="col-lg-8 mt-5 mt-lg-0">
            <form
              className={classes.email_form}
              // @ts-ignore
              ref={form}
              // @ts-ignore
              onSubmit={sendEmail}
            >
              <div className="row">
                <div className="col-md-6 form-group">
                  <input
                    type="text"
                    name="from_name"
                    className="form-control"
                    id="from_name"
                    placeholder="Your Name"
                  />
                </div>
                <div className="col-md-6 form-group mt-3 mt-md-0">
                  <input
                    type="email"
                    className="form-control"
                    name="from_email"
                    id="from_email"
                    placeholder="Your Email"
                    required
                  />
                </div>
              </div>
              <div className="form-group mt-3">
                <input
                  type="text"
                  className="form-control"
                  name="subject"
                  id="subject"
                  placeholder="Subject"
                />
              </div>
              <div className="form-group mt-3">
                <textarea
                  className="form-control"
                  name="message"
                  placeholder="Message"
                  required
                  rows={5}
                ></textarea>
              </div>
              <div className="my-3">
                <div className={classes.loading}>Loading</div>
                <div className={classes.error_message}></div>
                <div className={classes.sent_message}>
                  Your message has been sent. Thank you!
                </div>
              </div>
              <div className="text-center">
                <button className="btn btn-success" type="submit">
                  Send Message
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
