import Breadcrumbs from "../components/breadcrumbs/Breadcrumbs";
import ContactInfo from "../components/contactinfo/ContactInfo";

export default function Contact() {
  return (
    <>
      <Breadcrumbs>Contact</Breadcrumbs>
      <ContactInfo />
    </>
  );
}
