import CloudSecurityComponent from "../components/MultiCloudDeployment/CloudSecurity/CloudSecurityComponent";
import Breadcrumbs from "../components/breadcrumbs/Breadcrumbs";

const CloudSecurity = () => {
  return (
    <>
      <Breadcrumbs>Cloud Security</Breadcrumbs>
      <CloudSecurityComponent />
    </>
  );
};

export default CloudSecurity;
