import MultiCloudDeployment from "../components/MultiCloudDeployment";
import Breadcrumbs from "../components/breadcrumbs/Breadcrumbs";

const MultiCloudPage = () => {
  return (
    <>
      <Breadcrumbs>Multi Cloud Deployment</Breadcrumbs>
      <MultiCloudDeployment />
    </>
  );
};

export default MultiCloudPage;
