import CloudStrategyComponent from "../components/MultiCloudDeployment/CloudStrategy/CloudStrategyComponent";
import Breadcrumbs from "../components/breadcrumbs/Breadcrumbs";

const CloudStrategy = () => {
  return (
    <>
      <Breadcrumbs>Cloud Strategy</Breadcrumbs>
      <CloudStrategyComponent />
    </>
  );
};

export default CloudStrategy;
