import InfrastructureFoundationComponent from "../components/MultiCloudDeployment/InfrastructureFoundation/InfrastructureFoundationComponent";
import Breadcrumbs from "../components/breadcrumbs/Breadcrumbs";

const InfrastructureFoundation = () => {
  return (
    <>
      <Breadcrumbs>Infrastructure Foundation</Breadcrumbs>
      <InfrastructureFoundationComponent />
    </>
  );
};

export default InfrastructureFoundation;
