import PowerPlatformComponent from "../components/PowerPlatform/PowerPlatformComponent";
import Breadcrumbs from "../components/breadcrumbs/Breadcrumbs";

const PowerPlatform = () => {
  return (
    <>
      <Breadcrumbs>Power Platform Solution</Breadcrumbs>
      <PowerPlatformComponent />
    </>
  );
};

export default PowerPlatform;
